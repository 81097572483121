import React from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  /* min-width: ${props => props.theme.maxWidthMd};
  max-width: 1400px; */
  margin: 0 auto;
  position: relative;
  padding: 40px;
  padding-bottom: 0;
  

  ${props => 
    props.centered && 
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    `}

  @media ${props => props.theme.breakpoints.down('lg')} {
    padding: 0 2%;
  }

  @media ${props => props.theme.breakpoints.down('md')} {
    padding: 0 4%;
  }
`

export default function RowWrapper({children, centered, className}) {
  return (
    <Wrapper className={className} centered={centered}>{children}</Wrapper>
  )
}