import React from 'react'
import styled from 'styled-components';

const Button = styled.div`
  height: 8rem;
  width: 8rem;
  border-radius: 50%;
  background-color: ${props => props.bg || props.theme.colors.dark};
  color: ${props => props.col || props.theme.colors.body};
  visibility: ${props => props.show ? 'visible' : 'hidden'};
  margin: 0 auto;
  text-align: center;
  padding-top: 3.5rem;
  font-size: 1.3rem;
  cursor: pointer;
`
 
export default ({sec, sub, top, left, bg, col, show, click, text}) => {
 
 return (
 <Button onClick={click} className={`bu-sec-${sec}-sub-${sub}`} top={top} left={left} bg={bg} col={col} show={show} >
   {text ? text : 'Explore'}
 </Button>
 )
}